import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from 'react';
import {useDarkMode as useDarkModeHook} from "usehooks-ts";

type DarkModeContextType = {
  isDarkMode: boolean;
  enable: VoidFunction;
  disable: VoidFunction;
};

const Context= createContext<DarkModeContextType| undefined>(undefined);
export const useDarkMode = () => {
  const context = useContext(Context);
  if (!context) throw new Error('useDarkMode must be used within a DarkModeProvider');
  return context;
};

type DarkModeProviderProps = {
  children: ReactNode;
};

export const Provider: React.FC<DarkModeProviderProps> = ({ children }) => {

  const {isDarkMode, enable, disable} = useDarkModeHook()

  useEffect(() => {
    document.body.className = isDarkMode ? 'dark' : '';
  }, [isDarkMode]);

  return (
    <Context.Provider value={{ isDarkMode, enable, disable }}>
      {children}
    </Context.Provider>
  );
};