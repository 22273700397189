import React, {PropsWithChildren, useContext, useState} from "react";
import {List, Repeat, Set} from "immutable";
import {times} from "lodash";
import {ALPHABET, EMPTY_CHAR} from "@/constants";

interface ContextValueType {
  characters: List<string>;
  setIdx: (index: number, value: string) => void;
  removeLeft: VoidFunction;
  extendLeft: VoidFunction;
  removeRight: VoidFunction;
  extendRight: VoidFunction;
  resetCharacters: VoidFunction;
  setLengthChangeLeft: (n: number) => void;
  setLengthChangeRight: (n: number) => void;

  alphabetChars: Set<string>;
  removeAlphabetChar: (str: string) => void;
  addAlphabetChar: (str: string) => void;
  resetAlphabetChars: VoidFunction;
  addAlphabet: VoidFunction;
}

export const WORD_LEN_MIN = 1;
export const WORD_LEN_MAX = 15;
export const DEFAULT_LENGTH = 5;

export const Context = React.createContext<ContextValueType | undefined>(undefined);

export const useCharacterInput = () => {
  const context = useContext(Context);
  if (!context) throw new Error('useCharacterInput must be used within a CharacterInput Provider');
  return context;
};

export const Provider: React.FC<PropsWithChildren> = ({children}) => {
  const [characters, setCharacters] = useState<List<string>>(List(Repeat(EMPTY_CHAR, DEFAULT_LENGTH)));
  const [alphabetChars, setAlphabetChars] = useState<Set<string>>(Set());
  const setIdx = (index: number, char: string) => 0 <= index && index < characters.size && setCharacters(characters.set(index, char));
  const removeLeft = () => characters.size > WORD_LEN_MIN && setCharacters(characters.shift());
  const extendLeft = () => characters.size < WORD_LEN_MAX && setCharacters(characters.unshift(EMPTY_CHAR));
  const removeRight = () => characters.size > WORD_LEN_MIN && setCharacters(characters.pop());
  const extendRight = () => characters.size < WORD_LEN_MAX && setCharacters(characters.push(EMPTY_CHAR));
  const removeAlphabetChar = (char: string) => setAlphabetChars(alphabetChars.remove(char));
  const addAlphabetChar = (char: string) => setAlphabetChars(alphabetChars.add(char));
  const resetCharacters = () => setCharacters(List(Repeat(EMPTY_CHAR, characters.size)));
  const resetAlphabetChars = () => setAlphabetChars(Set());
  const addAlphabet = () => setAlphabetChars(Set(Object.values(ALPHABET)));

  // const removed = characters.map(currChar => currChar === char ? '' : currChar);
  // setCharacters(removed);

  const setLengthChangeLeft = (n: number) => {
    times(Math.max(characters.size - n, 0), removeLeft);
    times(Math.max(n - characters.size, 0), extendLeft);
  }

  const setLengthChangeRight = (n: number) => {
    times(Math.max(characters.size - n, 0), removeRight);
    times(Math.max(n - characters.size, 0), extendRight);
  }

  const value = {
    characters,
    alphabetChars,
    removeAlphabetChar,
    addAlphabetChar,
    resetAlphabetChars,
    addAlphabet,
    setIdx,
    removeLeft,
    extendLeft,
    removeRight,
    extendRight,
    resetCharacters,
    setLengthChangeRight,
    setLengthChangeLeft
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
};



