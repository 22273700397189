import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import {Set} from "immutable";
import {COLUMN_HEADER, DICTIONARY, FIND_BY} from "@/constants";
import {useToggle} from "usehooks-ts";


interface ContextValueType {
  findBy: FIND_BY;
  setFindBy: (newFindBy: FIND_BY) => void;
  dictionaries: Set<DICTIONARY>;
  addDictionary: (dict: DICTIONARY) => void;
  removeDictionary: (dict: DICTIONARY) => void;
  sortKey: COLUMN_HEADER;
  isSortAsc: boolean;
  setSortKey: (sortKey: COLUMN_HEADER) => void;
  toggleIsSortAsc: VoidFunction;
  setIsSortAsc: (isSortAsc: boolean) => void;
}

export const Context = React.createContext<ContextValueType | undefined>(undefined);

export const useSelectedOptions = () => {
  const context = useContext(Context);
  if (!context) throw new Error('useSelectedOptions must be used within a SelectedOptions Provider');
  return context;
};

export const Provider: React.FC<PropsWithChildren> = ({children}) => {

  const [findBy, setFindBy] = useState<FIND_BY>(FIND_BY.MATCH);
  const [dictionaries, setDictionaries] = useState(Set(Object.values(DICTIONARY)));
  const [sortKey, setSortKey] = useState<COLUMN_HEADER>(COLUMN_HEADER.WORD);
  const [isSortAsc, toggleIsSortAsc, setIsSortAsc] = useToggle(true);
  const addDictionary = (dict: DICTIONARY) => setDictionaries(dictionaries.add(dict));
  const removeDictionary = (dict: DICTIONARY) => setDictionaries(dictionaries.remove(dict));

  const value: ContextValueType = {
    findBy,
    setFindBy,
    dictionaries,
    addDictionary,
    removeDictionary,
    sortKey,
    isSortAsc,
    setSortKey,
    toggleIsSortAsc,
    setIsSortAsc
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};