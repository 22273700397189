import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import {useCharacterInput} from "@/contexts/CharacterInput";
import {useSelectedOptions} from "@/contexts/SelectedOptions";
import {useWordFinder} from "@/contexts/WordFinder";
import {WordDataRow} from "@/components/WordDataTable/WordDataTable";
import {FIND_BY} from "@/constants";

type ContextValueType = {
  data: Array<WordDataRow>;
  subData: Array<WordDataRow>;
  isLoading: boolean;
}

export const Context = React.createContext<ContextValueType | undefined>(undefined);

export const useOutputWords = () => {
  const context = useContext(Context);
  if (context === undefined) throw new Error('useOutputWords must be used within a OutputWords Provider');
  return context;
};

export const Provider: React.FC<PropsWithChildren> = ({children}) => {

  const {wordFinderAccessor} = useWordFinder();
  const {characters, alphabetChars} = useCharacterInput();
  const {dictionaries, findBy, sortKey, isSortAsc} = useSelectedOptions();

  const [data, setData] = useState<Array<WordDataRow>>([]);
  const [subData, setSubData] = useState<Array<WordDataRow>>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    switch (findBy) {
      case FIND_BY.MATCH:
        wordFinderAccessor.match(characters.toArray(), alphabetChars.toArray(), dictionaries.toArray())
          .then(setData)
          .then(() => setIsLoading(false));
        break;
      case FIND_BY.CONTAINS:
        wordFinderAccessor.contains(characters.toArray(), alphabetChars.toArray(), dictionaries.toArray())
          .then(setData)
          .then(() => setIsLoading(false));
        break;
      case FIND_BY.STARTS_WITH:
        wordFinderAccessor.startsWith(characters.toArray(), alphabetChars.toArray(), dictionaries.toArray())
          .then(setData)
          .then(() => setIsLoading(false));
        break;
      case FIND_BY.ENDS_WITH:
        wordFinderAccessor.endsWith(characters.toArray(), alphabetChars.toArray(), dictionaries.toArray())
          .then(setData)
          .then(() => setIsLoading(false));
        break;
    }
  }, [characters, alphabetChars, dictionaries, findBy]);

  useEffect(() => {
    const subData = [...data].sort((a, b) =>
      a[sortKey] === b[sortKey] ? 0 :
        (a[sortKey] < b[sortKey] ? -1 : 1) * (isSortAsc ? 1 : -1)
    ).slice(0, 1000);
    setSubData(subData);
  }, [data, sortKey, isSortAsc]);

  return (
    <Context.Provider value={{ data, subData, isLoading }}>
      {children}
    </Context.Provider>);
};




