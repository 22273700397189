export const DICTIONARY_FILE_NAME = "dictionary.bin";

export enum DICTIONARY {
  WORDLE = 'wordle',
  SCRABBLE = 'scrabble',
  ENGLISH = 'english'
}

export enum ALPHABET {
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g',
  H = 'h',
  I = 'i',
  J = 'j',
  K = 'k',
  L = 'l',
  M = 'm',
  N = 'n',
  O = 'o',
  P = 'p',
  Q = 'q',
  R = 'r',
  S = 's',
  T = 't',
  U = 'u',
  V = 'v',
  W = 'w',
  X = 'x',
  Y = 'y',
  Z = 'z'
}

export const SCRABBLE_SCORES: { [key in ALPHABET]: number } = {
  [ALPHABET.A]: 1, [ALPHABET.B]: 3, [ALPHABET.C]: 3, [ALPHABET.D]: 2, [ALPHABET.E]: 1,
  [ALPHABET.F]: 4, [ALPHABET.G]: 2, [ALPHABET.H]: 4, [ALPHABET.I]: 1, [ALPHABET.J]: 8,
  [ALPHABET.K]: 5, [ALPHABET.L]: 1, [ALPHABET.M]: 3, [ALPHABET.N]: 1, [ALPHABET.O]: 1,
  [ALPHABET.P]: 3, [ALPHABET.Q]: 10, [ALPHABET.R]: 1, [ALPHABET.S]: 1, [ALPHABET.T]: 1,
  [ALPHABET.U]: 1, [ALPHABET.V]: 4, [ALPHABET.W]: 4, [ALPHABET.X]: 8, [ALPHABET.Y]: 4,
  [ALPHABET.Z]: 10
};

export enum FIND_BY {
  MATCH = "match",
  CONTAINS = "contains",
  STARTS_WITH = "startsWith",
  ENDS_WITH = "endsWith"
}

export const EMPTY_CHAR = '';

export enum COLUMN_HEADER {
  WORD = "word",
  LENGTH = "length",
  SCRABBLE_SCORE = "scrabbleScore"
}