import {
  WordFinderContainsMessage, WordFinderDictionarySizeMessage,
  WordFinderEndsWithMessage,
  WordFinderMatchMessage,
  WordFinderMessage,
  WordFinderMessageType,
  WordFinderPromiseMessage,
  WordFinderRegisterUrlMessage,
  WordFinderResponse,
  WordFinderResponseBodyBase,
  WordFinderResponseBodyData, WordFinderResponseBodySize,
  WordFinderStartsWithMessage, WordFinderTrieSizeMessage
} from "@/workers/wordFinderMessages";
import {DICTIONARY} from "@/constants";

const isBrowser = typeof window !== "undefined";

export class WordFinderAccessor {

  private worker?: Worker;
  private pendingPromises: Map<number, { resolve: (response: any) => void, reject: (reason?: any) => void }> = new Map();
  private messageId = 0;

  constructor(url: string) {
    isBrowser && this.registerUrl(url);
  }

  public getWorker() {
    if (this.worker) return this.worker;
    this.worker = new Worker(new URL('../workers/wordFinder.worker.ts', import.meta.url));
    this.worker.addEventListener('message', this.processResponse.bind(this));
    return this.worker;
  }

  private getNextMessageId(): number {
    return this.messageId++;
  }

  public async registerUrl(url: string) {
    const message: WordFinderRegisterUrlMessage = {
      type: WordFinderMessageType.REGISTER_URL,
      data: { url }
    };

    const response = await this.sendMessage<WordFinderResponseBodyBase>(message);
    return response.isSuccess;
  }

  public async match(pattern: string[], excludeCharacters: string[], dictionaries: DICTIONARY[]) {
    const message: WordFinderMatchMessage = {
      type: WordFinderMessageType.MATCH,
      data: { pattern, excludeCharacters, dictionaries }
    };

    const response = await this.sendMessage<WordFinderResponseBodyData>(message);
    return response.data;
  }

  public async contains(pattern: string[], excludeCharacters: string[], dictionaries: DICTIONARY[], exactLength=false) {
    const message: WordFinderContainsMessage = {
      type: WordFinderMessageType.CONTAINS,
      data: { pattern, excludeCharacters, dictionaries, exactLength }
    };

    const response = await this.sendMessage<WordFinderResponseBodyData>(message);
    return response.data;
  }

  public async startsWith(pattern: string[], excludeCharacters: string[], dictionaries: DICTIONARY[], exactLength=false) {
    const message: WordFinderStartsWithMessage = {
      type: WordFinderMessageType.STARTS_WITH,
      data: { pattern, excludeCharacters, dictionaries, exactLength }
    };

    const response = await this.sendMessage<WordFinderResponseBodyData>(message);
    return response.data;
  }

  public async endsWith(pattern: string[], excludeCharacters: string[], dictionaries: DICTIONARY[], exactLength=false) {
    const message: WordFinderEndsWithMessage = {
      type: WordFinderMessageType.ENDS_WITH,
      data: { pattern, excludeCharacters, dictionaries, exactLength }
    };

    const response = await this.sendMessage<WordFinderResponseBodyData>(message);
    return response.data;
  }

  public async getDictionarySize(dictionary: DICTIONARY) {
    const message: WordFinderDictionarySizeMessage = {
      type: WordFinderMessageType.DICTIONARY_SIZE,
      data: { dictionary }
    };
    const response = await this.sendMessage<WordFinderResponseBodySize>(message);
    return response.size;
  }

  public async getTrieSize() {
    const message: WordFinderTrieSizeMessage = {
      type: WordFinderMessageType.SIZE,
      data: undefined
    };
    const response = await this.sendMessage<WordFinderResponseBodySize>(message);
    return response.size;
  }

  private async sendMessage<T>(body: WordFinderMessage): Promise<T> {
    return new Promise((resolve, reject) => {
      const messageId = this.getNextMessageId();
      this.pendingPromises.set(messageId, { resolve, reject });
      const toProcess: WordFinderPromiseMessage = {messageId, body};
      this.getWorker().postMessage(toProcess);
    });
  }

  private processResponse(e: MessageEvent<WordFinderResponse>): void {
    const { messageId, body, error } = e.data;
    const promiseHandlers = this.pendingPromises.get(messageId);
    if (!promiseHandlers) return;
    if (error) promiseHandlers.reject(error);
    promiseHandlers.resolve(body);
    this.pendingPromises.delete(messageId);
  }
}