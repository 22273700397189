import React, {createContext, useContext, useEffect, useMemo} from 'react';
import {WordFinderAccessor} from "@/workers/wordFinderAccessor";
import {withPrefix} from "gatsby";
import {DICTIONARY_FILE_NAME} from "@/constants";

type WordFinderContextType = {
  wordFinderAccessor: WordFinderAccessor
};

const wordFinderAccessor = new WordFinderAccessor(withPrefix(`/${DICTIONARY_FILE_NAME}`));
const Context = createContext<WordFinderContextType>({
  wordFinderAccessor
});

export const useWordFinder = () => {
  const context = useContext(Context);
  if (!context) throw new Error('useWordFinder must be used within a WordFinderProvider');
  return context;
};

type WordFinderProps = {
  children: React.ReactNode;
};

export const Provider: React.FC<WordFinderProps> = ({ children }) => {
  return (
    <Context.Provider value={{ wordFinderAccessor }}>
      {children}
    </Context.Provider>
  );
};
