import React from "react";
import "./src/styles/global.css"
import {Provider as WordFinderProvider} from "@/contexts/WordFinder";
import {Provider as SelectedOptionsProvider} from "./src/contexts/SelectedOptions";
import {Provider as CharacterInputContextProvider} from "./src/contexts/CharacterInput";
import {Provider as OutputWordsContextProvider} from "./src/contexts/OutputWords";
import {Provider as DarkModeProvider} from "./src/contexts/DarkMode";
import {GatsbyBrowser} from "gatsby";

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => (
  <WordFinderProvider>
    <SelectedOptionsProvider>
      <CharacterInputContextProvider>
        <OutputWordsContextProvider>
          <DarkModeProvider>
          {element}
          </DarkModeProvider>
        </OutputWordsContextProvider>
      </CharacterInputContextProvider>
    </SelectedOptionsProvider>
  </WordFinderProvider>
);